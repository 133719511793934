body {
  --lc-color-yellow: #FECC18;
  --lc-color-danger: #d9534f;
  --lc-color-blue: #70B0E0;
  --lc-color-darkblue: #386E9D;

  --lc-color-primary: #254F83;
  --lc-color-primary-hover: #5585BF;
  --lc-color-secondary: #386E9D;
  --lc-color-terciary: #70B0E0;
  --lc-color-lightgray: #E2E2E2;
  --lc-color-gray: #C6C6C5;
  --lc-color-darkgray: #898989;
}

.theme-mobilelogic {
  --background-color: #E2E2E2;
  --topbar-background-color: #254F83;
  --topbar-icon-background-image: url('../assets/img/brand/mobilelogic-icon.png');
  --topbar-logo-background-image: url('../assets/img/brand/mobilelogic-logo.png');
  --topbar-button-color: #F4F4F4;
  --topbar-button-active-background-color: #5585BF;
  --topbar-button-active-color: #f4f4f4;
  --topbar-button-hover-background-color: #5585BF;
  --topbar-button-hover-color: #f4f4f4;
  --topbar-button-toggle-background-color: #FECC18;
  --topbar-sidemenu-background-image: url(../assets/img/brand/mobilelogic-menu-hexagon.svg);

  .topbar {
    .logo {
      width: 300px !important;
      margin: 10px 0 10px 0 !important;
    }
  }
}



.theme-keyportal {
  --background-color: #EFEFEF;
  --topbar-background-color: #AFAFAF;
  --topbar-icon-background-image: url('../assets/img/brand/topfuel-icon.png');
  --topbar-logo-background-image: url('../assets/img/brand/topfuel-logo.png');
  --topbar-button-color: black;
  --topbar-button-active-background-color: #6F6F6F;
  --topbar-button-active-color: white;
  --topbar-button-hover-background-color: #6F6F6F;
  --topbar-button-hover-color: white;
  --topbar-button-toggle-background-color: #62FF09;
  --topbar-sidemenu-background-image: url(../assets/img/brand/mobilelogic-menu-hexagon.svg);

  .topbar {
    .logo {
      width: 220px !important;
      margin: 14px 0 14px 0 !important;
    }
  }
}



.theme-preem {
  --background-color: #EEEEEE;
  --topbar-background-color: #006341;
  --topbar-icon-background-image: url('../assets/img/brand/preem-icon.svg');
  --topbar-logo-background-image: url('../assets/img/brand/preem-logo.svg');
  --topbar-button-color: white;
  --topbar-button-active-background-color: #01823B;
  --topbar-button-active-color: white;
  --topbar-button-hover-background-color: #01823B;
  --topbar-button-hover-color: white;
  --topbar-button-toggle-background-color: #FFAC3A;
  --topbar-sidemenu-background-image: url(../assets/img/brand/mobilelogic-menu-hexagon.svg);

  .topbar {
    .logo {
      width: 100px !important;
      margin: 10px 0 10px 0 !important;
    }
  }
}



.theme-sato {
  --background-color: #E2E2E2;
  --topbar-background-color: white;
  --topbar-icon-background-image: url('../assets/img/brand/sato-icon.png');
  --topbar-logo-background-image: url('../assets/img/brand/sato-logo.png');
  --topbar-button-color: #00b1b7;
  --topbar-button-active-background-color: #00b1b7;
  --topbar-button-active-color: white;
  --topbar-button-hover-background-color: #00b1b7;
  --topbar-button-hover-color: white;
  --topbar-button-toggle-background-color: #03B1B7;
  --topbar-sidemenu-background-image: url(../assets/img/brand/mobilelogic-menu-hexagon.svg);
  --site-selector-background: linear-gradient(to right, #007AC9 0%, #0485C6 100%);
  --site-selector-color: white;
  --environment-selector-background: linear-gradient(to right, #0485C6 0%, #05A4BC 100%);
  --environment-selector-color: white;

  .topbar {
    .logo {
      width: 136px !important;
      margin: 12px 0 12px 16px !important;
    }
  }

  .designer-topbar {
    .title {
      color: gray !important;
    }
  }
}



.theme-servly {
  --background-color: #E2E2E2;
  --topbar-background-color: #000728;
  --topbar-icon-background-image: url('../assets/img/brand/servly-icon.svg');
  --topbar-logo-background-image: url('../assets/img/brand/servly-logo.svg');
  --topbar-button-color: white;
  --topbar-button-active-background-color: #254F83;
  --topbar-button-active-color: white;
  --topbar-button-hover-background-color: #254F83;
  --topbar-button-hover-color: white;
  --topbar-button-toggle-background-color: #D18DFF;
  --topbar-sidemenu-background-image: url(../assets/img/brand/mobilelogic-menu-hexagon.svg);

  .topbar {
    .logo {
      width: 300px !important;
      margin: 12px 0 12px 16px !important;
    }

    .icon {
      background-size: auto !important;
    }
  }
}
