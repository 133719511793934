.theme-nova {

  body .p-component {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.875rem;

    p {
      line-height: 1.5;
      margin: 0;
    }
  }

  body .p-button {
    text-transform: uppercase;
    font-weight: 600;
  }

  body .p-button.p-button-secondary, body .p-buttonset.p-button-secondary > .p-button {
    color: #333333;
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;
  }
  body .p-button.p-button-secondary:enabled:hover, body .p-buttonset.p-button-secondary > .p-button:enabled:hover {
    background-color: #c8c8c8;
    color: #333333;
    border-color: #c8c8c8;
  }
  body .p-button.p-button-secondary:enabled:focus, body .p-buttonset.p-button-secondary > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
  }
  body .p-button.p-button-secondary:enabled:active, body .p-buttonset.p-button-secondary > .p-button:enabled:active {
    background-color: #a0a0a0;
    color: #333333;
    border-color: #a0a0a0;
  }

  body .p-float-label {
    height: 100%;

    input:focus ~ label,
    input.p-filled ~ label,
    textarea:focus ~ label,
    textarea.p-filled ~ label,
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label {
      top: 5px;
    }
  }

  // I haven't reviewed the following styles after the primeng 17 upgrade

  body .p-card {
    position: relative;
    margin: 0.375rem;
    border-radius: 0;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

    height: calc(100% - .75rem);
    padding: .5rem;

    display: flex;
    flex-direction: column;

    .p-card-header {
      font-size: 1.5rem;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: visible;
      color: #46535A;
    }

    .p-card-body {
      flex: 1;
      min-height: 0;
      min-width: 0;
      padding: .5rem;
      overflow-y: auto;

      display: flex;
      flex-direction: column;

      .p-card-content {
        flex: 1;
        min-height: 0;
        min-width: 0;
        padding: 0;
        position: relative;
      }
    }
  }


  body .p-tree {
    border: 0;
    padding: 0;

    .p-treenode-selectable.p-treenode-content {
      line-height: 1.2rem;
    }

    .p-tree-container .p-treenode .p-treenode-content:focus {
      box-shadow: none;
      outline: 0 none;
    }

    .p-treenode-icon {
      // this is the same as setting the node class as 'fa'
      display: inline-block;
      font-family: 'Font Awesome 6 Pro';
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // this is the same as setting the node class as 'fa'
    }
  }

  body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #46535A;
    background-color: transparent;
  }

  body .p-tabview {
    height: 100%;

    .p-tabview-nav li .p-tabview-nav-link {
      padding: 0.5rem 1rem;

      .p-tabview-title {
        font-size: 0.875rem;
        line-height: normal;
      }
    }

    .p-tabview-panels {
      flex: 1;
      height: calc(100% - .9rem - 2em);
      min-height: 0;
      min-width: 0;
      padding: 0;

      .p-tabview-panel {
        background-color: white !important;
        overflow: auto; // this adds scroll to the panel. comment this and uncomment the min-height above to let it grow and the scroll will be handled by the parent container
        padding: 0.5rem;
        width: 100%;
        height: 100%;
      }
      .no-padding {
        > .p-tabview-panel {
          padding: 0;
        }
      }
    }
  }

  body .p-tabview.underline {
    .p-tabview-nav-content {
      scroll-padding-inline: 3rem;
    }
    .p-tabview-nav {
      background: #ffffff;
      border: 1px solid #e5e7eb;
      border-width: 0 0 2px 0;
    }
    .p-tabview-nav li {
      margin-right: 0;
    }
    .p-tabview-nav li .p-tabview-nav-link {
      border: solid #e5e7eb;
      border-width: 0 0 2px 0;
      border-color: transparent transparent #e5e7eb transparent;
      background: #ffffff;
      color: #6b7280;
      padding: 0.5rem 1rem;
      font-weight: 700;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      transition: box-shadow 0.2s;
      margin: 0 0 -2px 0;
    }
    .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #BFDBFE;
    }
    .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      background: #ffffff;
      border-color: #9ca3af;
      color: #6b7280;
    }
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: #ffffff;
      border-color: #3B82F6;
      color: #3B82F6;
    }
    .p-tabview-left-icon {
      margin-right: 0.5rem;
    }
    .p-tabview-right-icon {
      margin-left: 0.5rem;
    }
    .p-tabview-close {
      margin-left: 0.5rem;
    }
    .p-tabview-nav-btn.p-link {
      background: #ffffff;
      color: #3B82F6;
      width: 3rem;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border-radius: 0;
    }
    .p-tabview-nav-btn.p-link:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #BFDBFE;
    }
    .p-tabview-panels {
      background: #ffffff;
      padding: 1.25rem;
      border: 0 none;
      color: #4b5563;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  body .p-menu {
    width: auto;
  }

  body .p-dropdown {
    min-width: 5em;
    width: unset;
  }

  body .p-contextmenu,
  body .p-tieredmenu {
    min-width: 12.5em;
    width: unset;
  }

  body .p-contextmenu,
  body .p-menu,
  body .p-tieredmenu {
    .p-menuitem,
    .p-menuitem-text {
      white-space: nowrap;
    }
  }

  body .p-tooltip {
    max-width: 80vw !important;
  }

  body .p-dropdown-items-wrapper {
    max-height: 400px !important;
  }

  body .p-progressbar {
    background-color: transparent;
  }

  body .p-accordion {
    .p-accordion-header {
      margin-bottom: 0;
      color: #007ad9;

      &:focus,
      a:focus {
        box-shadow: none;
      }

      &:not(.p-disabled).p-highlight a,
      &:not(.p-disabled).p-highlight:hover a {
        color: inherit;
        background-color: inherit;
        border: 1px solid #c8c8c8;
        border-bottom-width: 0;
        .p-accordion-toggle-icon {
          color: inherit;
        }
      }
    }

    .p-accordion-content {
      background-color: transparent;
      border-top-width: 0;
    }
  }


}
